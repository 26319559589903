import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

export default function Navbar() {
  return (
    <div className="navbar-wrapper">
      <div className="title">Sufle x IVS Demo</div>
      <div className="navbar">
        <Link to={"/"}>Stream</Link>
        <Link to={"/download"}>Download</Link>
      </div>
    </div>
  );
}
