import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import { Dialog, Transition } from "@headlessui/react";

import Placeholder from "./placeholder";
import PlayerControls from "./PlayerControls";

import { CONTROLS, POSITION } from "./config";
import { isElementInViewport } from "./utils";

import "./MiniPlayer.css";

const CORNER_SPACE = 32;
const DEFAULT_POSITION = "auto";
const TRANSITION = "200ms ease-in-out";

const { IVSPlayer } = window;
const { ENDED, PLAYING, READY } = IVSPlayer.PlayerState;
const { ERROR } = IVSPlayer.PlayerEventType;
const { TEXT_METADATA_CUE } = IVSPlayer.PlayerEventType;

const MiniPlayer = (props) => {
  const { isPlayerSupported } = IVSPlayer;

  const {
    controls = [CONTROLS.mute, CONTROLS.close, CONTROLS.resize],
    position = POSITION.bottomRight,
    height = 154,
    width = 274,
    streamUrl,
    transition,
  } = props;

  const [loading, setLoading] = useState(true);
  const [isMiniPlayer, setIsMiniPlayer] = useState(false);
  const [muted, setMuted] = useState(false);

  const [playerPosition, setPlayerPosition] = useState({});
  const [playerSize, setPlayerSize] = useState({});
  const [metadata, setMetadata] = useState();

  const player = useRef(null);
  const playerBaseEl = useRef(null);
  const videoEl = useRef(null);
  const visibleRef = useRef(null);

  // handle case when autoplay with sound is blocked by browser
  useEffect(() => {
    if (!player.current) return;

    setMuted(player.current.isMuted());
  }, [loading]);

  const updatePlayer = useCallback(
    (isMini) => {
      let top = DEFAULT_POSITION;
      let right = DEFAULT_POSITION;
      let bottom = DEFAULT_POSITION;
      let left = DEFAULT_POSITION;

      let targetPosition = 0;
      let targetHeight = "100%";
      let targetWidth = "100%";

      if (isMini) {
        targetPosition = `${CORNER_SPACE}px`;
        targetHeight = `${height}px`;
        targetWidth = `${width}px`;
      }

      switch (position) {
        case POSITION.topLeft:
          top = targetPosition;
          left = targetPosition;

          break;
        case POSITION.topRight:
          top = targetPosition;
          right = targetPosition;

          break;
        case POSITION.bottomLeft:
          bottom = targetPosition;
          left = targetPosition;

          break;
        default:
          bottom = targetPosition;
          right = targetPosition;
      }

      setPlayerSize({
        height: targetHeight,
        width: targetWidth,
      });
      setPlayerPosition({
        top,
        right,
        bottom,
        left,
      });
    },
    [height, width, position]
  );

  const timeoutRef = useRef();

  const onStateChange = () => {
    const playerState = player.current.getState();

    console.log(`Player State - ${playerState}`);
    setLoading(playerState !== PLAYING);
  };

  const onError = (err) => {
    console.warn("Player Event - ERROR:", err);
  };
  const onMetadata = (data) => {
    const content = JSON.parse(data?.text);
    if (content.question === "clear") {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setMetadata(null);
    } else {
      if (metadata) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }
      setMetadata(content);
      timeoutRef.current = setTimeout(() => {
        setMetadata(null);
        clearTimeout(timeoutRef.current);
      }, 60000);
    }
  };

  useEffect(() => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    if (!isPlayerSupported) {
      console.warn(
        "The current browser does not support the Amazon IVS player."
      );

      return;
    }

    if (!player.current) {
      player.current = IVSPlayer.create();
      player.current.attachHTMLVideoElement(videoEl.current);
      player.current.load(streamUrl);
      player.current.play();

      player.current.addEventListener(READY, onStateChange);
      player.current.addEventListener(PLAYING, onStateChange);
      player.current.addEventListener(ENDED, onStateChange);
      player.current.addEventListener(ERROR, onError);
      player.current.addEventListener(TEXT_METADATA_CUE, onMetadata);
    }

    return () => {
      player.current.removeEventListener(READY, onStateChange);
      player.current.removeEventListener(PLAYING, onStateChange);
      player.current.removeEventListener(ENDED, onStateChange);
      player.current.removeEventListener(ERROR, onError);
      player.current.removeEventListener(TEXT_METADATA_CUE, onMetadata);
    };
  }, [IVSPlayer, isPlayerSupported, streamUrl]);

  useEffect(() => {
    console.log(metadata);
  }, [metadata]);

  useEffect(() => {
    const onVisibilityChange = () => {
      const visible = isElementInViewport(playerBaseEl.current);

      if (visible === visibleRef.current) return;

      visibleRef.current = visible;

      if (visible && player.current.isPaused()) {
        player.current.play();
      }

      if (!visible) {
        const playerRect = playerBaseEl.current.getBoundingClientRect();
        setPlayerSize({
          height: `${playerRect.height}px`,
          width: `${playerRect.width - CORNER_SPACE}px`,
        });
      }

      setTimeout(() => {
        setIsMiniPlayer(!visible);
      }, 100);
    };

    if (!isPlayerSupported) {
      return;
    }

    onVisibilityChange();
    updatePlayer(visibleRef.current);

    window.addEventListener("scroll", onVisibilityChange);
    window.addEventListener("resize", onVisibilityChange);

    return () => {
      window.removeEventListener("scroll", onVisibilityChange);
      window.removeEventListener("resize", onVisibilityChange);
    };
  }, [isPlayerSupported, updatePlayer]);

  useEffect(() => {
    updatePlayer(isMiniPlayer);
  }, [isMiniPlayer, updatePlayer]);

  const close = () => {
    player.current.pause();
    setIsMiniPlayer(false);
  };

  const resize = () => {
    const { offsetLeft, offsetTop } = playerBaseEl.current;

    window.scrollTo({
      top: offsetTop - 20,
      left: offsetLeft,
      behavior: "smooth",
    });
  };

  const toggleMute = () => {
    const shouldMute = !player.current.isMuted();

    player.current.setMuted(shouldMute);
    setMuted(shouldMute);
  };

  if (!isPlayerSupported) {
    return null;
  }

  const { top, right, bottom, left } = playerPosition;

  return (
    <div className="MiniPlayer pb-16" ref={playerBaseEl}>
      <div className="MiniPlayer-videoBox">
        <div className="absolute top-0 left-0 right-0 bottom-0 from-transparent to-black bg-gradient-to-b z-50 from-85% to-100% rounded-b-[10px]">
          <div className="absolute bottom-3 left-3 flex items-center">
            <div className="w-5 h-5 bg-red-600 rounded-full animate-pulse"></div>
            <h1 className="text-white ml-2 font-sans tracking-wide text-lg">LIVE</h1>
          </div>
        </div>
        <Placeholder loading={loading} />

        <div
          className={`MinPlayer-video${isMiniPlayer ? " small" : ""}`}
          style={{
            top,
            right,
            bottom,
            left,
            width: `${playerSize.width}`,
            height: `${playerSize.height}`,
            transition:
              transition && isMiniPlayer
                ? `height ${TRANSITION}, width ${TRANSITION}`
                : "none",
          }}
        >
          <video ref={videoEl} playsInline></video>

          {isMiniPlayer && (
            <PlayerControls
              controls={controls}
              muted={muted}
              onClose={close}
              onResize={resize}
              onMute={toggleMute}
            />
          )}
        </div>
        <Transition
          appear
          show={metadata !== null && metadata !== undefined}
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-10"
        >
          <Dialog
            open={metadata !== null && metadata !== undefined}
            onClose={() => {}}
            className="relative z-50"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-10"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-10"
            >
              <div className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Dialog.Panel className="relative mx-auto w-[400px] lg:w-[500px] rounded-xl bg-white p-8 h-min">
                <h1 className="font-bold text-[#001858] font-sans mb-4 pb-2 border-b border-b-[#001858] text-xl lg:text-2xl ">
                  {metadata?.question}
                </h1>
                <div className="flex flex-col space-y-2">
                  {metadata?.answers?.map((answer) => (
                    <p
                      key={answer}
                      className="lg:text-lg font-semibold text-[#0f0e17]"
                    >
                      {answer}
                    </p>
                  ))}
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
};

export default MiniPlayer;
