import React from "react";

import MiniPlayer from "./components/mini-player";
import { CONTROLS, POSITION } from "./components/mini-player";
import Download from "./components/download/index";
import Navbar from "./components/navbar/index";

import "./App.css";

const STREAM_PLAYBACK_URL = process.env.REACT_APP_PLAYBACK_URL;
import { Router, Routes, Route } from "react-router-dom";
const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <MiniPlayer
              streamUrl={STREAM_PLAYBACK_URL}
              controls={[CONTROLS.resize, CONTROLS.close, CONTROLS.mute]}
              position={POSITION.bottomRight}
              transition
            />
          }
        />
        {/*<Route path="/download" element={<Download />} />*/}
      </Routes>
    </div>
  );
};

export default App;
